import axios from 'axios';
import Vue from 'vue';

export const Services = new Vue({
  methods: {
    async geographics(country) {
      console.log('Fetching provinces for: ', country);
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/countries/?code=` + country)
        .then((response) => {
          localStorage.setItem('country', JSON.stringify(response.data.data));
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });

      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/provinces/?country=` + country)
        .then((response) => {
          localStorage.setItem('provinces', JSON.stringify(response.data.data));
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    },
    async getLocation() {
      if (!localStorage.getItem('country') || !localStorage.getItem('provinces')) {
        await axios
          .get(`https://ipinfo.io/json`)
          .then(async (resp) => {
            await this.geographics(resp.data.country);
          })
          .catch(async (error) => {
            console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
            await this.geographics('ES');
          });
      }
    },

    async getTechnologies() {
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/technologies`)
        .then(async (resp) => {
          localStorage.setItem('technologies', JSON.stringify(resp.data.data));
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    },

    async getMethodologies() {
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/methodologies`)
        .then(async (resp) => {
          localStorage.setItem('methodologies', JSON.stringify(resp.data.data));
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    },

    async getCertifications() {
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/certifications`)
        .then(async (resp) => {
          localStorage.setItem('certifications', JSON.stringify(resp.data.data));
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    },

    async getProfiles() {
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/profiles`)
        .then(async (resp) => {
          const response = resp.data;
          localStorage.setItem('profiles', JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    },

    async getCity(provinceId) {
      let params = 'provinceId=' + provinceId;
      params = provinceId ? '/?' + params : '';
      return await axios
        .get(`${process.env.VUE_APP_ROOT_API}/cities${params}`)
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          return error.response || error;
        });
    },

    async filterSkills(params) {
      await axios
        .get(`${process.env.VUE_APP_ROOT_API}/professional/skills/?technologies=` + params)
        .then((resp) => {
          const response = resp.data;
          if (response) {
            if (response.code == 200 && response.data !== 0) {
              window.Swal.fire({
                position: 'top-end',
                title: '<h4 style="text-align: left;">Buscando...</h4>',
                html:
                  '<div style="text-align: left;">Hasta el momento hemos encontrado <b>' +
                  response.data +
                  ' profesionales</b> que coinciden con tus criterios... seguimos buscando en nuestra base de datos</div>',
                showConfirmButton: false,
                timer: 6000,
                backdrop: false,
                showCloseButton: true
              });
            } else if (response.code == 404 || resp.status == 404) {
              console.log('error 400', resp);
            } else if (response.code == 500 || resp.status == 500) {
              console.log('error 500', resp);
            }
          } else {
            console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', response);
          }
        })
        .catch((error) => {
          console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
        });
    }
  }
});
