<template>
  <div class="home">
    <!-- Swiper-->
    <section class="section video-container" id="home" data-loop="false" data-simulate-touch="false"
      data-autoplay="true">

      <div class="video-wrapper">
        <div class="slide-video video-slide bg-image-3">
          <div class="slide-caption text-lg-left">
            <div class="container container-wide">
              <div class="row justify-content-lg-between justify-content-center">
                <div class="col-md-9 col-xl-6 col-lg-6 video-caption-wrapper computer-midle-size">
                  <div class="video-caption">
                    <img class="video-caption-overlay" src="/img/home-slider-04.webp"
                      alt="Buscas talentos IT para tu empresa" />
                    <!-- RD Video-->
                    <div class="rd-video play-on-scroll rd-video-player" data-rd-video-path="/video/video-lg-2">
                      <div class="rd-video-wrap">
                        <div class="rd-video-preloader"></div>
                        <video 
                          autoplay
                          loop
                          muted
                          playsinline
                          data-rd-video-path="/video/video-lg-2"
                          type="video/mp4">
                          <source src="/video/video-404" type="video/mp4">
                          <source src="/video/video-lg-2" type="video/mp4">
                          <img src="img/home-video.png" title="Your browser does not support video" />
                        </video>
                        <div class="rd-video-controls">
                          <!-- Play\Pause button-->
                          <a class="rd-video-play-pause mdi mdi-play icon icon-lg" href="#"></a>
                          <!-- Progress bar-->
                          <div class="rd-video-progress-bar">
                            <div class="current" style="width: 0%"></div>
                          </div>
                          <div class="rd-video-time">
                            <!-- currentTime-->
                            <span class="rd-video-current-time">00:38</span>
                            -
                            <!-- Track duration-->
                            <span class="rd-video-duration">02:49</span>
                          </div>
                          <div class="rd-video-volume-wrap">
                            <!-- Volume button-->
                            <a class="rd-video-volume mdi mdi-volume-high icon icon-sm" href="#"></a>
                            <div class="rd-video-volume-bar-wrap">
                              <!-- Volume bar-->
                              <div class="rd-video-volume-bar rd-video-volume-bar-vertical">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                  <div class="oveflow-wrapper">
                    <h2 class="big text-accent sub-text-size" data-caption-animate="fadeInUp" data-caption-delay="400"
                      v-html="$t('lang.home.section_1.banner.subtitle')"></h2>
                  </div>
                  <div class="oveflow-wrapper">
                    <h1 class="midle-size text-white" data-caption-animate="fadeInUp" data-caption-delay="500">
                      {{ $t('lang.home.section_1.banner.title') }}
                    </h1>
                  </div>
                  <div class="oveflow-wrapper">
                    <a class="button button-primary" data-caption-animate="fadeInUpSmall" data-caption-delay="350"
                      href="/cuentanos" @click="gaTellUs">
                      {{ $t('lang.home.section_1.banner.btn') }}
                  </a>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide" data-slide-bg="/img/itstaffing.webp">
          <div class="swiper-slide-caption">
            <div class="container container-wide">
              <h2 data-caption-animate="fadeInUp" data-caption-delay="300"
                v-html="$t('lang.home.section_1.banner_2.title')"></h2>
              <div class="oveflow-wrapper oveflow-top">
                <a class="button button-primary" data-caption-animate="fadeInUp" data-caption-delay="350"
                  href="/empresas" @click="gaBusiness">
                  {{ $t('lang.home.section_1.banner_2.btn') }}
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Swiper Navigation-->

    </section>

    <section class="section context-dark bg-accent section-cta">
      <div class="container container-wide width-xl">
        <div class="row row-30 align-items-center">
          <div class="col-xl-12 col-xxl-11 offset-xxl-1 text-left">
            <h5 class="ml-4 mr-4 show-title font-xl-text" v-html="$t('lang.home.section_2.title')"></h5>
            <br>
            <h5 class="ml-4 mr-4 font-xl-text" v-html="$t('lang.home.section_2.subtitle')"></h5>
          </div>
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img section-md bg-default">
      <div class="container width-xl">
        <div class="row row-50 justify-content-sm-center font-xl">
          <div class="col-md-12 col-lg-12 about-us">
            <h2>{{ $t('lang.home.section_3.title') }}</h2>

            <p class="recluting-size  text-md-left text-sm-left text-left" v-html="$t('lang.home.section_3.description_1')"></p>

            <p class="recluting-size text-md-left text-sm-left text-left" v-html="$t('lang.home.section_3.description_2')"></p>

            <p  class="recluting-size  text-md-left text-sm-left text-left" v-html="$t('lang.home.section_3.description_3')"></p>

            <p  class="recluting-size  text-md-left text-sm-left text-left" v-html="$t('lang.home.section_3.description_4')"></p>

            <br />
            <br />

            <h3 style="font-weight: 900">
              <span class="connecting-text">{{
              $t('lang.home.section_3.description_5')
              }}</span>
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section class="section home-image text-center context-dark section-md">
      <parallax>
      <div class="container width-xl">
        <div class="row justify-content-sm-center row-60">
          <h3>{{ $t('lang.home.section_4.title') }}</h3>
        </div>
        <div class="row justify-content-sm-center row-60">
          <div class="col-md-4 col-xl-4">
           
            <div class="progress-bar-circle" data-value="1" data-gradient="#2ed3ae, #2ed3ae" data-empty-fill="#fff"
              data-size="130" data-thickness="5">
              <article class="box-counter">
                <div class="box-counter__wrap">
                  <div class="counter">323</div>
                  <i>K</i>
                </div>
              </article>
            </div>
            <div class="progress-bar-circle-title">{{
            $t('lang.home.section_4.info_percentage_1')
            }}</div>
          </div>
          <div class="col-md-4 col-xl-4">
           
            <div class="progress-bar-circle" data-value="0.33" data-gradient="#2ed3ae, #2ed3ae" data-empty-fill="#fff"
              data-size="130" data-thickness="5">
              <span></span>
            </div>
            <div class="progress-bar-circle-title">{{
            $t('lang.home.section_4.info_percentage_2')
            }}</div>
          </div>
          <div class="col-md-4 col-xl-4">
            
            <div class="progress-bar-circle" data-value="1" data-gradient="#2ed3ae, #2ed3ae" data-empty-fill="#fff"
              data-size="130" data-thickness="5">
              <article class="box-counter">
                <div class="box-counter__wrap">
                  <i>+</i>
                  <div class="counter">4.2</div>
                  <i>M</i>
                </div>
              </article>
            </div>
            <div class="progress-bar-circle-title">
              <p v-html="$t('lang.home.section_4.info_percentage_3')"></p>
            </div>
          </div>
        </div>
      </div>
      </parallax>
    </section>

    <section class="section novi-bg novi-bg-img section-lg bg-default">
      <div class="container width-xl">
        <div class="row row-50">
          <div class="col-md-12 col-lg-12 about-us">
            <h4 class="section-color text-title-size ml-4 mr-4">
              {{ $t('lang.home.section_5.description_1') }}
            </h4>
            <h4 class="section-color">
              {{ $t('lang.home.section_5.description_2') }}
            </h4>
            <h4 class="section-color">
              {{ $t('lang.home.section_5.description_3') }}
            </h4>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeInLeft">
            <!-- Blurb circle-->
            <article class="blurb blurb-circle blurb-circle_centered">
              <div class="blurb-circle__icon">
                <span class="icon novi-icon linear-icon-menu3"></span>
              </div>
              <p class="blurb__title">{{ $t('lang.home.section_5.card_1.title')
              }}</p>
              <p class="blurb__middle_desc" v-html="$t('lang.home.section_5.card_1.description')"></p>
            </article>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeInDown">
            <!-- Blurb circle-->
            <article class="blurb blurb-circle blurb-circle_centered">
              <div class="blurb-circle__icon offset-lg-0 col-lg-12">
                <span class="icon novi-icon linear-icon-feather"></span>
              </div>
              <p class="blurb__title">{{ $t('lang.home.section_5.card_2.title')
              }}</p>
              <p class="blurb__middle_desc" v-html="$t('lang.home.section_5.card_2.description')"></p>
            </article>
          </div>

          <div class="col-md-6 col-lg-4 wow fadeInRight">
            <!-- Blurb circle-->
            <article class="blurb blurb-circle blurb-circle_centered">
              <div class="blurb-circle__icon">
                <span class="icon novi-icon linear-icon-bag2"></span>
              </div>
              <p class="blurb__title">{{ $t('lang.home.section_5.card_3.title')
              }}</p>
              <p class="blurb__middle_desc" v-html="$t('lang.home.section_5.card_3.description')"></p>
            </article>
          </div>
        </div>
      </div>
    </section>

    <section class="section text-center">
      <div class="row row-fix no-gutters">
        <div class="col-lg-6 novi-bg novi-bg-img cat-wrap bg-accent">
          <div class="cat-mod-1 wow blurIn">
            <h3>{{ $t('lang.home.section_6.card_1.title') }}</h3>
            <p class="mr-xl-4 ml-xl-4 pr-4 pl-4" v-html="$t('lang.home.section_6.card_1.description')"></p>
            <a class="button button-gray-light-outline" href="/empresas">{{
            $t('lang.home.section_6.btn')
            }}</a>
          </div>
        </div>
        <div class="col-lg-6 novi-bg novi-bg-img bg-image bg-image-3 cat-wrap">
        </div>
      </div>
    </section>

    <section class="section text-center">
      <div class="row row-fix no-gutters">
        <div class="col-lg-6 novi-bg novi-bg-img bg-image bg-image-5 cat-wrap">
        </div>
        <div class="col-lg-6 novi-bg novi-bg-img cat-wrap bg-accent">
          <div class="cat-mod-1 wow blurIn">
            <h3>{{ $t('lang.home.section_6.card_2.title') }}</h3>
            <p class="mr-xl-4 ml-xl-4 pr-4 pl-4" v-html="$t('lang.home.section_6.card_2.description')"></p>
            <a class="button button-gray-light-outline" href="/profesionales">{{
            $t('lang.home.section_6.btn')
            }}</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import { Services } from '../shared/services/index';

export default Vue.extend({
  name: 'Home',
  metaInfo: {
    title: 'Conecta con el talento que necesitas | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          '🔥 Proporcionamos Talento cuando más lo necesitas✅ Consigue excelentes profesionales para que formes tu gran equipo'
      }
    ]
  },
  methods: {
    gaTellUs() {
      this.$ga.event({
        'eventCategory': 'banner-home-1',
        'eventAction': '/cuentanos',
        'eventLabel': 'button-aqui'
      });
    },

    gaBusiness() {
      this.$ga.event({
        'eventCategory': 'banner-home-2',
        'eventAction': '/empresas',
        'eventLabel': 'button-conectemos'
      });
    }
  },
  mounted() {
    Services.getLocation();
    Services.getTechnologies();
    Services.getProfiles();
    Services.getMethodologies();
    Services.getCertifications();
  }
});
</script>
